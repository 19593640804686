import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';
// config
import { FIREBASE_API } from '../config-global';
//
import { ActionMapType, AuthStateType, AuthUserType, FirebaseContextType } from './types';
import { getApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import Bugsnag from '@bugsnag/js';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL'
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    isAdmin: boolean;
    user: AuthUserType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  isAdmin: false,
  user: null
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isAdmin: action.payload.user?.role,
      user: action.payload.user
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

export const storage = getStorage(firebaseApp);

if (window.location.hostname === 'localhost') {
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = useCallback(async () => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const token = await AUTH.currentUser?.getIdTokenResult();
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              isAdmin: token?.claims.admin,
              user: {
                ...user,
                role: token?.claims.admin
              }
            }
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              isAdmin: false,
              user: null
            }
          });
        }
      });
    } catch (error) {
      Bugsnag.notify(error);
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (state?.user) {
      Bugsnag.setUser(state.user?.uid, state.user?.email, state.user?.displayName || '');
    }
  }, [state]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(AUTH, email, password);
    const token = await AUTH.currentUser?.getIdTokenResult();
    return { userCredential, ...{ isAdmin: token?.claims.admin } };
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      await createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
        const userRef = doc(collection(DB, 'users'), res.user?.uid);

        await setDoc(userRef, {
          uid: res.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`
        });
      });
    },
    []
  );

  const role = useCallback(async () => {
    const tokenResult = await AUTH?.currentUser?.getIdTokenResult();
    return tokenResult?.claims;
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    signOut(AUTH);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      isAdmin: state.isAdmin,
      user: state.user,
      method: 'firebase',
      login,
      register,
      logout,
      role
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.isAdmin,
      login,
      register,
      logout,
      role
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
