// routes
import { PATHS } from '../../../routes/paths';
// icons
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import FeedTwoToneIcon from '@mui/icons-material/FeedTwoTone';
import RestaurantTwoToneIcon from '@mui/icons-material/RestaurantTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import LiveHelpTwoToneIcon from '@mui/icons-material/LiveHelpTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import ContactPhoneTwoToneIcon from '@mui/icons-material/ContactPhoneTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import AdUnitsTwoToneIcon from '@mui/icons-material/AdUnitsTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import QrCode2TwoToneIcon from '@mui/icons-material/QrCode2TwoTone';

// ----------------------------------------------------------------------

export const ICONS = {
  sectors: <DashboardTwoToneIcon sx={{ fontSize: 25 }} />,
  events: <EventTwoToneIcon sx={{ fontSize: 25 }} />,
  news: <FeedTwoToneIcon sx={{ fontSize: 25 }} />,
  catering: <RestaurantTwoToneIcon sx={{ fontSize: 25 }} />,
  information: <InfoTwoToneIcon sx={{ fontSize: 25 }} />,
  notificationSend: <CampaignTwoToneIcon sx={{ fontSize: 25 }} />,
  admins: <AdminPanelSettingsTwoToneIcon sx={{ fontSize: 25 }} />,
  visitors: <AssignmentIndTwoToneIcon sx={{ fontSize: 25 }} />,
  duk: <LiveHelpTwoToneIcon sx={{ fontSize: 25 }} />,
  documents: <DescriptionTwoToneIcon sx={{ fontSize: 25 }} />,
  partners: <HandshakeTwoToneIcon sx={{ fontSize: 25 }} />,
  contacts: <ContactPhoneTwoToneIcon sx={{ fontSize: 25 }} />,
  banners: <AdUnitsTwoToneIcon sx={{ fontSize: 25 }} />,
  qrLinks: <QrCode2TwoToneIcon sx={{ fontSize: 25 }} />,
  home: <HomeTwoToneIcon sx={{ fontSize: 60 }} />
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'nav.sectors',
        path: PATHS.sectors.root,
        icon: ICONS.sectors
      },
      {
        title: 'nav.qrLinks',
        path: PATHS.qrLinks.root,
        icon: ICONS.qrLinks
      },
      {
        title: 'nav.events',
        path: PATHS.events.root,
        icon: ICONS.events
      },
      {
        title: 'nav.news',
        path: PATHS.news.root,
        icon: ICONS.news,
        children: [
          {
            title: '',
            path: 'news/lt',
            flagIcon: '/assets/icons/flags/ic_flag_lt.svg'
          },
          {
            title: '',
            path: 'news/en',
            flagIcon: '/assets/icons/flags/ic_flag_en.svg'
          }
        ]
      },
      {
        title: 'nav.banners',
        path: PATHS.banners.root,
        icon: ICONS.banners,
        children: [
          {
            title: 'labels.home',
            path: PATHS.banners.list('home')
          }
        ]
      },
      {
        title: 'nav.catering',
        path: PATHS.catering.root,
        icon: ICONS.catering
      },
      {
        title: 'nav.information',
        path: PATHS.information.root,
        icon: ICONS.information,
        children: [
          {
            title: 'nav.bars',
            path: PATHS.information.list('bar')
          },
          {
            title: 'nav.tualets',
            path: PATHS.information.list('toilet')
          },
          {
            title: 'nav.entrances',
            path: PATHS.information.list('entrance')
          },
          {
            title: 'nav.parking',
            path: PATHS.information.list('parking')
          },
          {
            title: 'nav.premiumLounge',
            path: PATHS.information.list('premiumLounge')
          },
          {
            title: 'nav.vipLodge',
            path: PATHS.information.list('vipLodge')
          }
        ]
      },
      {
        title: 'nav.notificationSend',
        path: PATHS.notificationSend.root,
        icon: ICONS.notificationSend
      },
      {
        title: 'nav.admins',
        path: PATHS.admins.root,
        icon: ICONS.admins
      },
      {
        title: 'nav.visitors',
        path: PATHS.visitors.root,
        icon: ICONS.visitors
      },
      {
        title: 'nav.duk',
        path: PATHS.duk.root,
        icon: ICONS.duk,
        children: [
          {
            title: '',
            path: 'duk/lt',
            flagIcon: '/assets/icons/flags/ic_flag_lt.svg'
          },
          {
            title: '',
            path: 'duk/en',
            flagIcon: '/assets/icons/flags/ic_flag_en.svg'
          }
        ]
      },
      {
        title: 'nav.documents',
        path: PATHS.documents.root,
        icon: ICONS.documents,
        children: [
          {
            title: '',
            path: 'documents/lt',
            flagIcon: '/assets/icons/flags/ic_flag_lt.svg',
            children: [
              { title: 'nav.privacyPolicy', path: PATHS.documents.subSub('lt', 'privacy-policy') },
              { title: 'nav.rules', path: PATHS.documents.subSub('lt', 'terms-and-conditions') }
            ]
          },
          {
            title: '',
            path: 'documents/en',
            flagIcon: '/assets/icons/flags/ic_flag_en.svg',
            children: [
              { title: 'nav.privacyPolicy', path: PATHS.documents.subSub('en', 'privacy-policy') },
              { title: 'nav.rules', path: PATHS.documents.subSub('en', 'terms-and-conditions') }
            ]
          }
        ]
      },
      {
        title: 'nav.partners',
        path: PATHS.partners.root,
        icon: ICONS.partners,
        children: [
          {
            title: '',
            path: PATHS.partners.sub('lt'),
            flagIcon: '/assets/icons/flags/ic_flag_lt.svg'
          },
          {
            title: '',
            path: PATHS.partners.sub('en'),
            flagIcon: '/assets/icons/flags/ic_flag_en.svg'
          }
        ]
      },
      {
        title: 'nav.contacts',
        path: PATHS.contacts.root,
        icon: ICONS.contacts,
        children: [
          {
            title: '',
            path: PATHS.contacts.sub('lt'),
            flagIcon: '/assets/icons/flags/ic_flag_lt.svg'
          },
          {
            title: '',
            path: PATHS.contacts.sub('en'),
            flagIcon: '/assets/icons/flags/ic_flag_en.svg'
          }
        ]
      }
    ]
  }
];

export default navConfig;
