import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
//
import {
  Page404,
  LoginPage,
  HomePage,
  AdminsPage,
  EventsPage,
  NewsPage,
  CateringPage,
  InformationPage,
  NotificationSendPage,
  VisitorsPage,
  DukPage,
  DocumentsPage,
  PartnersPage,
  ContactsPage,
  ManageAdminPage,
  ManagePartnerPage,
  SubPage,
  SubSubPage,
  ManageDukPage,
  ManageNewsPage,
  InfoCardPage,
  ManageInfoPage,
  LegalPage,
  SectorsPage,
  RedirectPage,
  BannersPage,
  BannerCardPage,
  QRLinksPage,
  ManageQrLinkPage,
  QrLinkGeneratePage,
  LinkPage
} from './elements';
import { PATHS } from './paths';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: 'legal',
      children: [
        { path: 'lt/privacy-policy', element: <LegalPage /> },
        { path: 'en/privacy-policy', element: <LegalPage /> },
        { path: 'lt/terms-and-conditions', element: <LegalPage /> },
        { path: 'en/terms-and-conditions', element: <LegalPage /> }
      ]
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      )
    },
    {
      path: PATHS.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <HomePage />,
          index: true
        },
        {
          path: 'admins',
          children: [
            { path: '', element: <AdminsPage /> },
            { path: 'edit/:id', element: <ManageAdminPage /> },
            { path: 'create', element: <ManageAdminPage /> }
          ]
        },
        {
          path: 'events',
          children: [{ path: '', element: <EventsPage /> }]
        },
        {
          path: 'news',
          children: [
            { path: '', element: <SubPage /> },
            { path: ':lng', element: <NewsPage /> },
            { path: ':lng/edit/:id', element: <ManageNewsPage /> },
            { path: ':lng/create', element: <ManageNewsPage /> }
          ]
        },
        {
          path: 'catering',
          children: [{ path: '', element: <CateringPage /> }]
        },
        {
          path: 'information',
          children: [
            { path: '', element: <InformationPage /> },
            { path: ':type', element: <InfoCardPage /> },
            { path: ':type/edit/:id', element: <ManageInfoPage /> },
            { path: ':type/create', element: <ManageInfoPage /> }
          ]
        },
        {
          path: 'notification-send',
          children: [{ path: '', element: <NotificationSendPage /> }]
        },
        {
          path: 'visitors',
          children: [{ path: '', element: <VisitorsPage /> }]
        },
        {
          path: 'duk',
          children: [
            { path: '', element: <SubPage /> },
            { path: ':lng', element: <DukPage /> },
            { path: ':lng/edit/:id', element: <ManageDukPage /> },
            { path: ':lng/create', element: <ManageDukPage /> }
          ]
        },
        {
          path: 'documents',
          children: [
            { path: '', element: <SubPage /> },
            { path: ':lng', element: <SubSubPage /> },
            { path: ':lng/:urlEnd', element: <DocumentsPage /> }
          ]
        },
        {
          path: 'partners',
          children: [
            { path: '', element: <SubPage /> },
            { path: ':lng', element: <PartnersPage /> },
            { path: ':lng/edit/:id', element: <ManagePartnerPage /> },
            { path: ':lng/create', element: <ManagePartnerPage /> }
          ]
        },
        {
          path: 'contacts',
          children: [
            { path: '', element: <SubPage /> },
            { path: ':lng', element: <ContactsPage /> }
          ]
        },
        {
          path: 'sectors',
          children: [{ path: '', element: <SectorsPage /> }]
        },
        {
          path: 'banners',
          children: [
            { path: '', element: <BannersPage /> },
            { path: ':type', element: <BannerCardPage /> }
          ]
        },
        {
          path: 'qr-links',
          children: [
            { path: '', element: <QRLinksPage /> },
            { path: 'edit/:id', element: <ManageQrLinkPage /> },
            { path: 'generate', element: <QrLinkGeneratePage /> }
          ]
        }
      ]
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }]
    },
    {
      path: 'link',
      children: [{ path: ':id', element: <LinkPage /> }]
    },
    {
      path: 'download',
      element: <RedirectPage />
    },
    {
      path: 'menu',
      element: <RedirectPage />
    },
    {
      path: 'order',
      children: [{ path: ':id', element: <RedirectPage /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
