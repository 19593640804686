import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const AdminsPage = Loadable(lazy(() => import('../pages/AdminsPage')));
export const EventsPage = Loadable(lazy(() => import('../pages/EventsPage')));
export const NewsPage = Loadable(lazy(() => import('../pages/NewsPage')));
export const CateringPage = Loadable(lazy(() => import('../pages/CateringPage')));
export const InformationPage = Loadable(lazy(() => import('../pages/InformationPage')));
export const NotificationSendPage = Loadable(lazy(() => import('../pages/NotificationSendPage')));
export const VisitorsPage = Loadable(lazy(() => import('../pages/VisitorsPage')));
export const DukPage = Loadable(lazy(() => import('../pages/DukPage')));
export const ManageDukPage = Loadable(lazy(() => import('../pages/ManageDukPage')));
export const SubPage = Loadable(lazy(() => import('../pages/SubPage')));
export const SubSubPage = Loadable(lazy(() => import('../pages/SubSubPage')));
export const DocumentsPage = Loadable(lazy(() => import('../pages/DocumentsPage')));
export const PartnersPage = Loadable(lazy(() => import('../pages/PartnersPage')));
export const ContactsPage = Loadable(lazy(() => import('../pages/ContactsPage')));
export const ManageAdminPage = Loadable(lazy(() => import('../pages/ManageAdminPage')));
export const ManagePartnerPage = Loadable(lazy(() => import('../pages/ManagePartnerPage')));
export const ManageNewsPage = Loadable(lazy(() => import('../pages/ManageNewsPage')));
export const InfoCardPage = Loadable(lazy(() => import('../pages/InfoCardPage')));
export const ManageInfoPage = Loadable(lazy(() => import('../pages/ManageInfoPage')));
export const LegalPage = Loadable(lazy(() => import('../pages/LegalPage')));
export const SectorsPage = Loadable(lazy(() => import('../pages/SectorsPage')));
export const RedirectPage = Loadable(lazy(() => import('../pages/RedirectPage')));
export const LinkPage = Loadable(lazy(() => import('../pages/LinkPage')));
export const BannersPage = Loadable(lazy(() => import('../pages/BannersPage')));
export const BannerCardPage = Loadable(lazy(() => import('../pages/BannerCardPage')));
export const QRLinksPage = Loadable(lazy(() => import('../pages/QrLinksPage')));
export const ManageQrLinkPage = Loadable(lazy(() => import('../pages/ManageQrLinkPage')));
export const QrLinkGeneratePage = Loadable(lazy(() => import('../pages/QrLinkGeneratePage')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
