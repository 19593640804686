import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized, isAdmin } = useAuthContext();
  if (isAuthenticated && isAdmin) {
    return <Navigate to="/" />;
  }

  if (!isInitialized && !isAdmin) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
