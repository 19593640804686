// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login'
};

export const PATHS = {
  root: ROOT,
  admins: {
    root: path(ROOT, '/admins'),
    edit: (id: string) => path(ROOT, `/admins/edit/${id}`),
    create: path(ROOT, '/admins/create')
  },
  events: {
    root: path(ROOT, '/events')
  },
  news: {
    root: path(ROOT, '/news'),
    list: (lng: 'lt' | 'en') => path(ROOT, `/news/${lng}`),
    create: (lng: 'lt' | 'en') => path(ROOT, `/news/${lng}/create`),
    edit: (id: string, lng: 'lt' | 'en') => path(ROOT, `/news/${lng}/edit/${id}`)
  },
  catering: {
    root: path(ROOT, '/catering')
  },
  information: {
    root: path(ROOT, '/information'),
    list: (type: 'bar' | 'toilet' | 'entrance' | 'premiumLounge' | 'vipLodge' | 'parking') =>
      path(ROOT, `/information/${type}`),
    edit: (
      id: string,
      type: 'bar' | 'toilet' | 'entrance' | 'premiumLounge' | 'vipLodge' | 'parking'
    ) => path(ROOT, `/information/${type}/edit/${id}`),
    create: (type: 'bar' | 'toilet' | 'entrance' | 'premiumLounge' | 'vipLodge' | 'parking') =>
      path(ROOT, `/information/${type}/create`)
  },
  notificationSend: {
    root: path(ROOT, '/notification-send')
  },
  visitors: {
    root: path(ROOT, '/visitors')
  },
  duk: {
    root: path(ROOT, '/duk'),
    list: (lng: 'lt' | 'en') => path(ROOT, `/duk/${lng}`),
    create: (lng: 'lt' | 'en') => path(ROOT, `/duk/${lng}/create`),
    edit: (id: string, lng: 'lt' | 'en') => path(ROOT, `/duk/${lng}/edit/${id}`)
  },
  documents: {
    root: path(ROOT, '/documents'),
    sub: (lng: 'lt' | 'en') => path(ROOT, `/documents/${lng}`),
    subSub: (lng: 'lt' | 'en', urlEnd: 'privacy-policy' | 'terms-and-conditions') =>
      path(ROOT, `/documents/${lng}/${urlEnd}`)
  },
  partners: {
    root: path(ROOT, '/partners'),
    sub: (lng: 'lt' | 'en') => path(ROOT, `/partners/${lng}`),
    create: (lng: 'lt' | 'en') => path(ROOT, `/partners/${lng}/create`),
    edit: (id: string, lng: 'lt' | 'en') => path(ROOT, `/partners/${lng}/edit/${id}`)
  },
  contacts: {
    root: path(ROOT, '/contacts'),
    sub: (lng: 'lt' | 'en') => path(ROOT, `/contacts/${lng}`)
  },
  sectors: {
    root: path(ROOT, '/sectors')
  },
  banners: {
    root: path(ROOT, '/banners'),
    list: (type: 'home') => path(ROOT, `/banners/${type}`)
  },
  qrLinks: {
    root: path(ROOT, '/qr-links'),
    generate: path(ROOT, '/qr-links/generate'),
    edit: (id: string) => path(ROOT, `/qr-links/edit/${id}`)
  }
};
